import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";

import { getCookie, setCookie } from "../cookies/cookiesManager";
import { Messager } from "../pages/Main/MainPage";
import { UserToken } from "../services/UserToken";
import { doLoginByReminder } from "../services/login";

interface AuthProviderProps {
  children: ReactNode;
}

interface User {
  ROLES: string[];
  exp: number;
  id: number;
  is_admin: boolean;
  iss: string;
  sub: string;
  user_id: number;
  username: string;
  email: string;
  contract: number; //??
  company_name: string; //??
  company: number; //??
  user: number; //??
}

interface setUserProps {
  token: string;
  reminder: string;
}

interface AuthContextData {
  user: User | null;
  logout(): void;
  setAuthUser(data: setUserProps): Promise<void>;
  hasPermission(requiredPermission: string): boolean;
}

export const AuthContext = createContext({} as AuthContextData);

export function parseJwt(token: string) {
  if (!token) {
    return null;
  }

  var base64Url = token.split(".")[1];
  var base64 = base64Url.replace("-", "+").replace("_", "/");
  var json = JSON.parse(window.atob(base64));
  return json;
}

function AuthProvider({ children }: AuthProviderProps) {
  const [user, setUser] = useState<User | null>(null);
  const [loading, setLoading] = useState(true);
  const exdays = 30;

  async function setAuthUser({
    token,
    reminder,
  }: setUserProps) {
    console.log("setReminder "+reminder);
    try {
      var user: User = parseJwt(token);

      setCookie("@numberwin:login", JSON.stringify(user), exdays);
      setCookie("@numberwin:token", token, exdays);
      setCookie("@numberwin:reminder", reminder, exdays);
      setUser(user);

      UserToken.reminder = reminder;
      UserToken.login = user.username;
      UserToken.email = user.email;
      UserToken.auth = token;
    } catch (e) {
      console.log(e);
    }
  }

  function hasPermission(requiredPermission: string) {
    if (!user) {
      return false;
    }
    // Verifique se a permissão requerida está presente nas permissões do usuário
    const hasPermission = user.ROLES.includes(requiredPermission);
    if (!hasPermission) {
      Messager.message(
        "Usuário Inválido!",
        "O usuário logado não é um cliente!",
        false,
        () => {
          exit();
        }
      );
    }
    return hasPermission;
  }

  useEffect(() => {
    const isTokenValid = (storagetoken: any) => {
      var tokenParsed = parseJwt(storagetoken);
      if (tokenParsed == null) {
        return false;
      }
      return tokenParsed.exp < new Date();
    };

    async function loadUserStorageDate() {
      const storageUser = getCookie("@numberwin:login");
      const storagetoken = getCookie("@numberwin:token");
      const storagereminder = getCookie("@numberwin:reminder");
      if (storageUser && storagetoken) {
        const user = JSON.parse(storageUser);
        if (isTokenValid(storagetoken)) {
          setUser(user);

          UserToken.login = user.username;
          UserToken.email = user.email;
          UserToken.auth = storagetoken;
          UserToken.reminder = storagereminder;

          setCookie("@numberwin:login", storageUser, exdays);
          setCookie("@numberwin:token", storagetoken, exdays);
          setCookie("@numberwin:reminder", storagereminder, exdays);
        }
      }else{
        if(storagereminder){
          //tenta fazer login com o reminder
          //se não funcionar, deleta o reminder
          doLoginByReminder(storagereminder, (response: any) => {
            if (response) {
              setAuthUser({
                token: response,
                reminder: storagereminder
              });
            }else{
              setCookie("@numberwin:reminder", "", exdays);
            }
            setLoading(false);
          });
        }
      }
      setLoading(false);
    }
    loadUserStorageDate();
  }, []);

  useEffect(() => {
    document.addEventListener("logout", logout);//saida devido a erro de login
    document.addEventListener("exit", exit);//saida ativa do usuário

    return () => {
      document.removeEventListener("logout", logout);
      document.removeEventListener("exit", exit);
    };
  }, []);

  async function logout() {
    console.log("logout");
    setUser(null);
    setCookie("@numberwin:token", "", exdays);
    UserToken.reminder = "";
    UserToken.login = "";
    UserToken.email = "";
    UserToken.auth = "";
    window.location.href =
      window.location.href.substring(0, window.location.href.indexOf("?")) +
      "?pag=0";
  }

  async function exit() {
    setUser(null);
    setCookie("@numberwin:token", "", exdays);
    setCookie("@numberwin:reminder", "", exdays);
    UserToken.reminder = "";
    UserToken.login = "";
    UserToken.email = "";
    UserToken.auth = "";
    window.location.href = window.location.href.substring(0, window.location.href.indexOf("?")) + "?pag=0";
  }

  return (
    <AuthContext.Provider value={{ user, logout, setAuthUser, hasPermission }}>
      {loading ? /*<Loading /> TODO*/ <div></div> : <>{children}</>}
    </AuthContext.Provider>
  );
}

function useAuth() {
  const context = useContext(AuthContext);

  return context;
}

export { AuthProvider, useAuth };
