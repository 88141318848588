import { SetStateAction, useEffect, useRef, useState } from "react";
import { number } from "yargs";
import { useAuth } from "../../../contexts/AuthContext";
import {
  CampaignCategory,
  checkCupons,
  checkWallet,
  getAllUserRaffle,
  getProfileImage,
  getWinnerRafflesFromUser,
  requestDeposit,
  searchWinnerRafflesFromUser,
} from "../../../services/login";
import { UserToken } from "../../../services/UserToken";
import { format } from "../../../utils/Utils";
import { CardProps } from "../Components/Card";
import { DataSquare } from "../Components/DataSquare";
import { DropdownSelectionButton } from "../Components/DropdownSelectionButton";
import { Selection } from "../Components/Selection";
import { ResultsGrid } from "../Components/ResultsGrid";
import { Messager } from "../MainPage";
import { AllRafflesResult, FillRaffleImages } from "../PaginaInicio";
import { ModalDeposito } from "./ModalDeposito";
import { BigDataSquare } from "../Components/BigDataSquare";
import { BigDataSquare_M } from "../Components/BigDataSquare_M";
import { ResultsGrid_M } from "../Components/ResultsGrid_M";

export interface PaginaProfileProps {}

export function PaginaProfile_M({}: PaginaProfileProps) {
  const sorteios = useRef<CardProps[]>([]);
  const btSelected = useRef<number>(0);
  const [sorteiosContemplados, setSorteiosContemplados] = useState<CardProps[]>([]);
  const [sorteiosFiltrados, setSorteiosFiltrados] = useState<CardProps[]>([]);
  const [balance, setBalance] = useState<number>(0);
  const [countActive, setCountActive] = useState<number>(0);
  const [countInactive, setCountInactive] = useState<number>(0);
  const [countTotal, setCountTotal] = useState<number>(0);
  const { hasPermission } = useAuth();
  const logged = hasPermission("ROLE_CLIENT");
  const [imgProfile, setImgProfile] = useState<any>("");
  const [depositWindow, setDepositWindow] = useState<boolean>(false);
  const filterButtons = [
    {
      label: "Todos Cupons",
      onClick: () => {
        btSelected.current =(0);
        setSorteiosFiltrados([...sorteios.current]);
      },
      isSelected: () => {
        return btSelected.current === 0;
      },
    },
    {
      label: "Cupons Ativos",
      onClick: () => {
        btSelected.current =(1);
        const sorteiosF = [];
        for (let index = 0; index < sorteios.current.length; index++) {
          const element = sorteios.current[index];
          if (element.raffle.isActive) {
            sorteiosF.push(element);
          }
        }
        setSorteiosFiltrados(sorteiosF);
      },
      isSelected: () => {
        return btSelected.current === 1;
      },
    },
    {
      label: "Cupons Encerrados",
      onClick: () => {
        btSelected.current =(2);
        const sorteiosF = [];
        for (let index = 0; index < sorteios.current.length; index++) {
          const element = sorteios.current[index];
          if (!element.raffle.isActive) {
            sorteiosF.push(element);
          }
        }
        setSorteiosFiltrados(sorteiosF);
      },
      isSelected: () => {
        return btSelected.current === 2;
      },
    },
    {
      label: "Cupons Contemplados",
      onClick: () => {
        btSelected.current =(3);
        const sorteiosF = [...sorteiosContemplados];
        setSorteiosFiltrados(sorteiosF);
      },
      isSelected: () => {
        return btSelected.current === 3;
      },
    },
  ];

  useEffect(() => {
    getAllUserRaffle((rest: AllRafflesResult) => {
      const data: CardProps[] = [];
      for (let index = 0; index < rest.content.length; index++) {
        const element = rest.content[index];
        FillRaffleImages(element, () => {
          data.push({
            raffle: element,
          });
          sorteios.current = ([...data]);
          setSorteiosFiltrados([...data]);
          if(btSelected.current>0){
            filterButtons[btSelected.current].onClick();
          }
        });
      }
      sorteios.current = (data);
      setSorteiosFiltrados(data);
      if(btSelected.current>0){
        filterButtons[btSelected.current].onClick();
      }
    });
    const params = {
      pagination: {
        page: 0,
        size: 1000,
        sortBy: "id",
        sortDir: "ASC"
      },
      data: {
        status: "INACTIVE",
      }
    }
    searchWinnerRafflesFromUser(params,(rest: any)=>{
      const data: CardProps[] = [];
      for (let index = 0; index < rest.content.length; index++) {
        const element = rest.content[index].raffle;
        element.id = rest.content[index].id;
        FillRaffleImages(element, () => {
          data.push({
            raffle: element,
          });
          setSorteiosContemplados([...data]);
        });
      }
      setSorteiosContemplados(data);
    })
    checkWallet((resp: any) => {
      setBalance(resp.balance);
    });
    checkCupons((resp: any) => {
      setCountActive(resp.countActive);
      setCountInactive(resp.countInactive);
      setCountTotal(resp.countTotal);
    });
    if (logged) {
      getProfileImage((file: any) => {
        if (!file) {
          setImgProfile("");
          return;
        }
        var reader = new FileReader();
        reader.readAsDataURL(file);

        // here we tell the reader what to do when it's done reading...
        reader.onloadend = (readerEvent) => {
          if (readerEvent) {
            var content = readerEvent.target?.result; // this is the content!
            if (content) {
              setImgProfile(content);
            }
          }
        };
      });
    }
  }, []);

  /*
  if (sorteios.length === 0) {
    for (let index = 0; index < 15; index++) {
      sorteios.push({
        title: "titulo",
        icon: "clock.png",
        line: "blablabla",
        cardheadimage: "green_background2.png",
        description: "GOLDEN NUMBER",
        prize: "Apartamento",
        ranking: "1º lugar",
        gray: index % 3 === 1 || index % 3 === 2,
        idPrize: 4,
      });
    }
    setSorteiosFiltrados([...sorteios]);
  }*/
  return (
    <div
      style={{
        color: "white",
        paddingTop: "100px",
        width: "100%",
        textAlign: "center",
      }}
    >
      {depositWindow ? <ModalDeposito setModalOpen={setDepositWindow} /> : null}
      <div
        style={{
          width: "100%",
          height: "560px",
          minHeight: "430px",
          overflow: "hidden",
        }}
      >
        <div
          style={{
            color: "white",
            textAlign: "left",
            paddingLeft: "65px",
            fontSize: "30px",
          }}
        >
          <div>Bem Vindo, <br/>{UserToken.login}</div>
          <div
            style={{
              position: "relative",
              backgroundImage:
                "url(" + require("../../../assets/images/profilebar.png") + ")",
              height: "200px",
              backgroundRepeat: "no-repeat",
              backgroundAttachment: "fixed",
              backgroundSize: "100% 100%",
              marginRight: "65px",
              borderRadius: "15px",
              borderColor: "white",
              borderStyle: "solid",
              borderWidth: "1px",
            }}
          >
            <img
              style={{
                position: "absolute",
                top: "10px",
                right: "10px",
                backgroundColor: "#419802",
                borderRadius: "5px",
                padding: "5px",
                borderColor: "white",
                borderStyle: "solid",
                borderWidth: "1px",
                cursor: "pointer",
              }}
              src="bell.png"
              width={"20px"}
              height={"20px"}
              onClick={() => {
                console.log("TODO");
                console.log("Abrir Avisos");
              }}
            />
            <img
              style={{
                position: "absolute",
                top: "10px",
                right: "50px",
                backgroundColor: "#419802",
                borderRadius: "5px",
                padding: "5px",
                borderColor: "white",
                borderStyle: "solid",
                borderWidth: "1px",
                cursor: "pointer",
              }}
              src="cog.png"
              width={"20px"}
              height={"20px"}
              onClick={() => {
                window.location.href =
                  window.location.href.substring(
                    0,
                    window.location.href.indexOf("?")
                  ) +
                  "?pag=" +
                  2;
              }}
            />
            <img
              style={{
                position: "absolute",
                bottom: "10px",
                left: "10px",
                backgroundColor: "#419802",
                borderRadius: "5px",
                padding: "5px",
                borderColor: "white",
                borderStyle: "solid",
                borderWidth: "1px",
              }}
              src="arrowR.png"
              width={"20px"}
              height={"20px"}
            />
            <img
              style={{
                position: "absolute",
                bottom: "-20px",
                left: "calc(50% - 64px)",
                backgroundColor: "#419802",
                borderRadius: "67px",
                padding: "5px",
                borderColor: "white",
                borderStyle: "solid",
                borderWidth: "3px",
              }}
              src={imgProfile ? imgProfile : "iconUser.png"}
              width={"120px"}
              height={"120px"}
            />
            <div
              style={{
                position: "absolute",
                top: "220px",
                left: "0",
                width: "100%",
                textAlign: "center",
              }}
            >
              <div>{UserToken.login}</div>
              <div style={{ fontSize: "20px", color: "gray" }}>
                {UserToken.email}
              </div>
              <div style={{display:"flex", justifyContent:"space-between", paddingTop:"10px" }}>
                <div/>
                <button
                  style={{
                    borderRadius: "20px",
                    backgroundColor: "white",
                    borderStyle: "solid",
                    borderColor: "transparent",
                    display: "flex",
                    justifyContent: "space-around",
                    padding: "5px",
                    paddingLeft: "10px",
                    paddingRight: "10px",
                  }}
                  onClick={() => {
                    setDepositWindow(true);
                  }}
                >
                  <img src="cloudCoin.png" />
                  <div
                    style={{
                      paddingTop: "5px",
                      paddingLeft: "5px",
                      fontWeight: "bold",
                    }}
                  >
                    COMPRAR CRÉDITO
                  </div>
                </button>
                <div/>
              </div>
            </div>
          </div>
          <div style={{ height: "150px" }}>
            {/* Espaço reservado para o nome do usuario*/}
          </div>
            
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "1fr 1fr 1fr 1fr",
              gridTemplateRows: "auto",
              marginRight: "70px",
            }}
          >
            <BigDataSquare_M
              label={"Crédito Ativo"}
              value={format(balance, "Decimal")}
              img={"sign1.png"}
              width={"20%"}
            />
            <BigDataSquare_M
              label={"Cupons Ativos"}
              value={format(countActive, "Integer")}
              img={"sign2.png"}
              width={"20%"}
            />
            <BigDataSquare_M
              label={"Todos os Cupons"}
              value={format(countTotal, "Integer")}
              img={"sign3.png"}
              width={"20%"}
            />
            <BigDataSquare_M
              label={"Cupons Encerrados"}
              value={format(countInactive, "Integer")}
              img={"sign4.png"}
              width={"20%"}
            />
          </div>
        </div>
      </div>
      <div
        style={{
          paddingTop: "30px",
          backgroundImage:
            "url(" +
            require("../../../assets/images/green_background.png") +
            ")",
          backgroundSize: "cover",
        }}
      >
        <ResultsGrid_M
          data={sorteiosFiltrados}
          btLabel={btSelected.current === 3?"CONTEMPLAR":undefined}
          buttons={filterButtons}
        />
      </div>
    </div>
  );
}
