import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { CampanhaRelampago } from "../Components/CampanhaRelampago";
import { CardProps } from "../Components/Card";
import { ResultsGrid } from "../Components/ResultsGrid";
import {
  CampaignCategory,
  CampaignType,
  getAllCampaignRaffles,
} from "../../../services/login";
import { AllRafflesResult, FillRaffleImages } from "../PaginaInicio";

export interface PaginaCampanhasProps {
  tipo: string;
  background: string;
}

export function PaginaCampanhas({ tipo, background }: PaginaCampanhasProps) {
  const sorteios = useRef<CardProps[]>([]);
  const [sorteiosFiltrados, setSorteiosFiltrados] = useState<CardProps[]>([]);
  const btSelected = useRef<number>(0);
  const [menuHeight, setHeight] = useState<any>(null);
  const buttonFilter = [
    {
      label: "Todos Sorteios",
      onClick: () => {
        btSelected.current = 0;
        setSorteiosFiltrados([...sorteios.current]);
      },
      isSelected: () => {
        return btSelected.current === 0;
      },
    },
    {
      label: "Sorteios Ativos",
      onClick: () => {
        btSelected.current = 1;
        const sorteiosF = [];
        for (let index = 0; index < sorteios.current.length; index++) {
          const element = sorteios.current[index];
          if (element.raffle.isActive) {
            sorteiosF.push(element);
          }
        }
        setSorteiosFiltrados(sorteiosF);
      },
      isSelected: () => {
        return btSelected.current === 1;
      },
    },
    {
      label: "Sorteios Encerrados",
      onClick: () => {
        btSelected.current = 2;
        const sorteiosF = [];
        for (let index = 0; index < sorteios.current.length; index++) {
          const element = sorteios.current[index];
          if (!element.raffle.isActive) {
            sorteiosF.push(element);
          }
        }
        setSorteiosFiltrados(sorteiosF);
      },
      isSelected: () => {
        return btSelected.current === 2;
      },
    },
  ];

  useLayoutEffect(() => {
    const mh = document.getElementById("header-menu")?.offsetHeight;

    setHeight(mh);
  }, [setHeight]);

  const campanhaButtons: any[] = [
    {
      label: "Campanhas de Celular",
      type: "Celular",
    },
    {
      label: "Campanhas de CPF",
      type: "CPF",
    },
    {
      label: "Campanhas de CNPJ",
      type: "CNPJ",
    },
    {
      label: "Campanhas de Insc. Mobiliária",
      type: "Insc.Mob.",
    },
    {
      label: "Campanhas de Placas de Carro",
      type: "Carro",
    },
  ];

  useEffect(() => {
    getAllCampaignRaffles(
      CampaignCategory.translate(tipo),
      (rest: AllRafflesResult) => {
        const data: CardProps[] = [];
        var counter = 0;
        for (let index = 0; index < rest.content.length; index++) {
          const element = rest.content[index];
          FillRaffleImages(element, () => {
            counter++;
            data.push({
              raffle: element,
            });
            sorteios.current = ([...data]);
            setSorteiosFiltrados([...data]);
            buttonFilter[btSelected.current].onClick();
          });
        }
        sorteios.current = (data);   
        setSorteiosFiltrados(data); 
      }
    );
  }, []);
  /*
  if (sorteios.length === 0) {
    for (let index = 0; index < 15; index++) {
      sorteios.push({
        title: "titulo",
        icon: "clock.png",
        line: "blablabla",
        cardheadimage: "green_background2.png",
        description: "GOLDEN NUMBER",
        prize: "Apartamento",
        ranking: "1º lugar",
        gray: index % 3 == 2,
        idPrize: 4,
      });
    }
    setSorteiosFiltrados(sorteios);
  }*/

  const typeName = (t: string) => {
    const name = campanhaButtons.find((i) => i?.type === t);

    if (name) {
      return name?.label;
    } else {
      return "";
    }
  };
  return (
    <div
      style={{
        color: "white",
        paddingTop: "200px",
        width: "100%",
        textAlign: "center",
      }}
    >
      <div
        style={{
          position: "absolute",
          top: "0px",
          left: "0px",
          width: "100%",
          height: "500px",
          overflow: "hidden",
        }}
      >
        <div
          style={{
            position: "relative",
            paddingLeft: "100px",
            width: "calc(90% - 100px)",
          }}
        >
          <div
            style={{
              position: "absolute",
              left: "0px",
              top: "250px",
              fontSize: "30px",
              textAlign: "right",
              textTransform: "uppercase",
              fontWeight: "800",
              width:"100%",
            }}
          >
            <div style={{display:"flex", width:"100%", flexWrap:"wrap", justifyContent:"space-between"}}>
              <div style={{width:"500px", height:"100px"}}></div>
              <div style={{width:"220px", height:"1px"}}/>
              <div style={{ }}><br/><br/>
              {" " + typeName(tipo)}
              </div>
            </div>
          </div>
            <div style={{position:"relative"}}>
              <div style={{position:"absolute", left:"0px", top:"100px"}}>
                  <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div></div>
              <div style={{ zIndex: "100" }}>
                <CampanhaRelampago menuHeight={menuHeight}/>
              </div>
              <div></div>
            </div>
              </div>
            </div>
          <div
            style={{
              position: "absolute",
              bottom: "0px",
              left: "0px",
              width: "100%",
              display: "flex",
              pointerEvents: "none",
            }}
          >
            <img
              src="gradient.png"
              style={{
                width: "100%",
                objectFit: "cover",
                overflow: "hidden",
              }}
            />
          </div>
        </div>
        <img src={background} style={{ width: "100%", minHeight: "500px" }} />
      </div>
      <div
        style={{
          paddingTop: "300px",
          backgroundImage:
            "url(" +
            require("../../../assets/images/green_background.png") +
            ")",
          backgroundSize: "cover",
        }}
      >
        <ResultsGrid
          data={sorteiosFiltrados}
          buttons={buttonFilter}
        />
      </div>
    </div>
  );
}
