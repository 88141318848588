import { Children, Dispatch, SetStateAction, useEffect, useLayoutEffect, useState } from "react";
import { LoginTab } from "../Main/Components/LoginTab";
import { RegisterTab } from "../Main/Components/RegisterTab";
import { RegisterTabPart2 } from "../Main/Components/RegisterTabPart2";
import { TopButtons } from "../Main/Components/TopButtons";
import {
  AllRafflesResult,
  FillRaffleImages,
  PaginaInicio,
  Raffle,
  RegisterData,
} from "../Main/PaginaInicio";
import { PaginaContato } from "./Rodape/PaginaContato";
import { PaginaFaq } from "./Rodape/PaginaFaq";
import { PaginaSobre } from "./Rodape/PaginaSobre";
import { PaginaTermos } from "./Rodape/PaginaTermos";
import { PaginaSorteio } from "./Sorteio/PaginaSorteio";
import { PaginaSorteioLive } from "./Sorteio/PaginaSorteioLive";
import "./MainPage.css";
import { PaginaConfig } from "./Profile/PaginaConfig";
import { PaginaProfile } from "./Profile/PaginaProfile";
import { PaginaCampanhas } from "./Campanhas/PaginaCampanhas";
import { PaginaSorteioDetails } from "./Sorteio/PaginaSorteioDetails";
import pj from "../../../package.json";
import { UserToken } from "../../services/UserToken";
import { getAllRaffles, searchRafflesByID } from "../../services/login";
import { ModalDeposito } from "./Profile/ModalDeposito";
import { PaginaSorteioCheckout } from "./Sorteio/PaginaSorteioCheckout";
import { TopButtons_M } from "./Components/TopButtons_M";
import { PaginaSorteioDetails_M } from "./Sorteio/PaginaSorteioDetails_M";
import { PaginaSorteio_M } from "./Sorteio/PaginaSorteio_M";
import { PaginaInicio_M } from "./PaginaInicio_M";
import { PaginaCampanhas_M } from "./Campanhas/PaginaCampanhas_M";
import { Rodape } from "./Rodape";
import { Rodape_M } from "./Rodape_M";
import { PaginaProfile_M } from "./Profile/PaginaProfile_M";
import { PaginaConfig_M } from "./Profile/PaginaConfig_M";

export interface SorteiosProps {}
export class Messager {
  static setTitle: Dispatch<SetStateAction<string>>;
  static setMessage: Dispatch<SetStateAction<string>>;
  static setConfirm: Dispatch<SetStateAction<string>>;
  static setInvertColors: Dispatch<SetStateAction<boolean>>;
  static setBuyCredit: Dispatch<SetStateAction<undefined | boolean>>;
  static callback?: () => void;

  static message(
    title: string,
    msg: string,
    buy?: boolean,
    callback?: () => void
  ) {
    Messager.setTitle(title);
    Messager.setMessage(msg);
    Messager.setConfirm("OK");
    Messager.setInvertColors(true);
    Messager.setBuyCredit(buy);
    Messager.callback = callback;
  }

  static executeCallback() {
    if (Messager.callback) {
      Messager.callback();
      Messager.callback = undefined;
    }
  }
}

export const pagInicio = 0;
export const pagSorteio = 1;
export const pagSorteioConfig = 2;
export const pagProfile = 3;
export const pagSobre = 4;
export const pagFaq = 5;
export const pagTermos = 6;
export const pagContato = 7;
export const pagSorteioDetails = 8;
export const pagCampanhas = 9;
export const pagSorteioCheckout = 10;

export function MainPage({}: SorteiosProps) {
  const [modalCompraCreditoOpen, setModalCompraCreditoOpen] =
    useState<boolean>(false);
  const [loginTabActive, setLoginTabActive] = useState<boolean>(false);
  const [registerTabActive, setRegisterTabActive] = useState<boolean>(false);
  const [registerTabP2Active, setRegisterTabP2Active] =
    useState<boolean>(false);
  const [registerData, setRegisterData] = useState<RegisterData>();
  const [activePage, setActivePage] = useState<number>(-1);
  const [title, setTitle] = useState<string>("");
  const [message, setMessage] = useState<string>("");
  const [confirm, setConfirm] = useState<string>("");
  const [buyCredit, setBuyCredit] = useState<undefined | boolean>(false);
  const [invertColors, setInvertColors] = useState<boolean>(true);
  const [currentSmall, setCurrentSmall] = useState<boolean>(true);
  const [newSmall, setNewSmall] = useState<boolean>(false);

  Messager.setTitle = setTitle;
  Messager.setMessage = setMessage;
  Messager.setConfirm = setConfirm;
  Messager.setInvertColors = setInvertColors;
  Messager.setBuyCredit = setBuyCredit;

  const handleButtonClick = () => {
    Messager.executeCallback();
    Messager.setMessage("");
    if (buyCredit !== undefined && buyCredit) {
      setModalCompraCreditoOpen(true);
    }
  };

  const [pages, setPages] = useState<React.ReactNode>();
  const [size, setSize] = useState(0);
  useLayoutEffect(() => {
    function updateSize() {
      setSize(window.innerWidth);
      setNewSmall(window.innerWidth<1000);
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  var pag = urlParams.get("pag");
  


  if (pag) {
    if (activePage != Number(pag)||currentSmall!=newSmall) {
      if(newSmall){
        switch (Number(pag)) {
          case pagInicio:
            setPages(<PaginaInicio_M />);
            break;
          case pagSorteio:
            setPages(<PaginaSorteio_M />);
            break;
          case pagSorteioConfig:
            setPages(<PaginaConfig_M />);
            break;
          case pagProfile:
            setPages(<PaginaProfile_M />);
            break;
          case pagSobre:
            setPages(<PaginaSobre />);
            break;
          case pagFaq:
            setPages(<PaginaFaq />);
            break;
          case pagTermos:
            setPages(<PaginaTermos />);
            break;
          case pagContato:
            setPages(<PaginaContato />);
            break;
          case pagSorteioDetails:
            const myid = urlParams.get("raffleId");
            searchRafflesByID(myid, (element: any) => {
              FillRaffleImages(element, () => {
                setPages(<PaginaSorteioDetails_M raffle={element} />);
              });
            });
            break;
          case pagSorteioCheckout:
            const id = urlParams.get("raffleId");
            setPages(<PaginaSorteioCheckout raffleId={""+id} />);
            break;
          case pagCampanhas:
            setPages(
              <PaginaCampanhas_M
                tipo={urlParams.get("tipo") + ""}
                background={urlParams.get("background") + ""}
              />
            );
            break;
          default:
            setPages(<PaginaInicio />);
        }
      }else{
        switch (Number(pag)) {
          case pagInicio:
            setPages(<PaginaInicio />);
            break;
          case pagSorteio:
            setPages(<PaginaSorteio />);
            break;
          case pagSorteioConfig:
            setPages(<PaginaConfig />);
            break;
          case pagProfile:
            setPages(<PaginaProfile />);
            break;
          case pagSobre:
            setPages(<PaginaSobre />);
            break;
          case pagFaq:
            setPages(<PaginaFaq />);
            break;
          case pagTermos:
            setPages(<PaginaTermos />);
            break;
          case pagContato:
            setPages(<PaginaContato />);
            break;
          case pagSorteioDetails:
            const myid = urlParams.get("raffleId");
            searchRafflesByID(myid, (element: any) => {
              FillRaffleImages(element, () => {
                setPages(<PaginaSorteioDetails raffle={element} />);
              });
            });
            break;
          case pagSorteioCheckout:
            const id = urlParams.get("raffleId");
            setPages(<PaginaSorteioCheckout raffleId={""+id} />);
            break;
          case pagCampanhas:
            setPages(
              <PaginaCampanhas
                tipo={urlParams.get("tipo") + ""}
                background={urlParams.get("background") + ""}
              />
            );
            break;
          default:
            setPages(<PaginaInicio />);
        }
      }
      setCurrentSmall(newSmall);
      setActivePage(Number(pag));
    }
  } else {
    pag = "0";
    if (activePage != 0) {
      setActivePage(0);
      setPages(<PaginaInicio />);
    }
  }

  useEffect(() => {
    if (registerData != undefined) {
      if (registerTabActive) {
        setRegisterTabActive(false);
        setRegisterTabP2Active(true);
      }
    }
  }, [registerData]);
  return (
    <div
      style={{
        minWidth: "390px",
        minHeight: "500px",
        backgroundColor: "black",
        overflow: "hidden",
      }}
    >
      <div style={{ position: "relative" }}>
        {modalCompraCreditoOpen && (
          <ModalDeposito setModalOpen={setModalCompraCreditoOpen} />
        )}
        {message !== "" ? (
          <div
            style={{
              position: "fixed",
              top: "calc(50% - 150px)",
              left: "calc(50% - 150px)",
              width: "300px",
              height: "200px",
              backgroundColor: invertColors ? "black" : "white",
              borderRadius: "15px",
              borderColor: "green",
              borderStyle: "solid",
              zIndex: "100000",
            }}
          >
            <div
              style={{
                width: "100%",
                textAlign: "center",
                fontSize: "24px",
                color: invertColors ? "white" : "black",
                fontWeight: "bold",
                paddingTop: "10px",
              }}
            >
              {title}
            </div>
            <div
              style={{
                width: "calc(100% - 20px)",
                height: "90px",
                paddingLeft: "20px",
                paddingRight: "20px",
                paddingTop: "10px",
                fontSize: "16px",
                color: invertColors ? "white" : "black",
              }}
            >
              {message}
            </div>
            <div style={{ display: "flex", justifyContent: "space-around" }}>
              <button
                style={{
                  padding: "10px",
                  backgroundColor: "green",
                  color: "white",
                  borderRadius: "5px",
                  borderColor: "transparent",
                  borderWidth: "1px",
                  width: "80px",
                }}
                onClick={handleButtonClick}
              >
                {confirm}
              </button>
            </div>
          </div>
        ) : (
          <div></div>
        )}
      </div>
      <div
        style={{
          position: "relative",
          width: "100%",
          zIndex: 999,
        }}
      >
        {loginTabActive ? (
          <LoginTab
            setModalOpen={setLoginTabActive}
            setRegisterOpen={setRegisterTabActive}
          />
        ) : (
          <div />
        )}
        {registerTabActive ? (
          <RegisterTab
            setModalOpen={setRegisterTabActive}
            setNextPage={setRegisterData}
            setLoginOpen={setLoginTabActive}
          />
        ) : (
          <div />
        )}
        {registerTabP2Active ? (
          <RegisterTabPart2
            setModalOpen={setRegisterTabP2Active}
            registerData={registerData}
            setLoginOpen={setLoginTabActive}
          />
        ) : (
          <div />
        )}
        <div
          style={{
            position: "absolute",
            top: "0px",
            left: "0px",
            width: "100%",
            height: "100%",
            backgroundColor: "#00000022",
          }}
        >
        {size<1000?<TopButtons_M
            setLoginTabActive={setLoginTabActive}
            setRegisterTabActive={setRegisterTabActive}
            activePage={activePage}
          />:
          <TopButtons
            setLoginTabActive={setLoginTabActive}
            setRegisterTabActive={setRegisterTabActive}
            activePage={activePage}
          />}
        </div>
      </div>
      {pages ? (
        pages
      ) : (
        <div
          style={{
            fontSize: "60px",
            color: "gold",
            widows: "100%",
            textAlign: "center",
            padding: "50px",
          }}
        >
          Carregando...
        </div>
      )}
      <div className="part5" style={{height:"100%"}}>
        {size<1000?<Rodape_M />:<Rodape />}
      </div>
    </div>
  );
}
