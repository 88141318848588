import { useState } from "react";
import { changePassword } from "../../../services/login";
import { RectInput } from "../Components/RectInput";
import { RectInputUpload } from "../Components/RectInputUpload";
import { RectSelect } from "../Components/RectSelect";
import { Messager } from "../MainPage";

export interface Props {}

export function ConfigSenha({}: Props) {
  const [colorNewPass, setColorNewPass] = useState<string>("#cccccc");
  const [colorConPass, setColorConPass] = useState<string>("#cccccc");
  return (
    <div>
      <div
        style={{
          backgroundColor: "white",
          borderRadius: "10px",
          padding: "20px",
          borderColor: "white",
          borderStyle: "solid",
          borderWidth: "3px",
          width: "calc(100% - 50px)",
        }}
      >
        <div style={{ fontSize: "20px", display: "flex" }}>
          <div>
            <b>Senha</b>
          </div>
          <img
            src="icon_info.png"
            style={{ paddingLeft: "5px", paddingTop: "5px" }}
          />
        </div>
        <hr color="gray" />
        <div>
          <RectInput
            label={"Senha Atual"}
            type={"password"}
            id={"passcur"}
            width={"100%"}
          />
          <RectInput
            label={"Nova Senha"}
            type={"password"}
            id={"passnew"}
            borderColor={colorNewPass}
            width={"100%"}
          />
          <RectInput
            label={"Confirmar Senha"}
            type={"password"}
            id={"passcon"}
            borderColor={colorConPass}
            width={"100%"}
          />
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div></div>
            <button
              style={{
                padding: "10px",
                backgroundColor: "green",
                color: "white",
                borderRadius: "5px",
                borderColor: "transparent",
                borderWidth: "1px",
                marginRight: "20px",
                width: "80px",
              }}
              onClick={() => {
                const pass =
                  (document.getElementById("passcur") as HTMLInputElement)
                    .value + "";
                const newpass =
                  (document.getElementById("passnew") as HTMLInputElement)
                    .value + "";
                const conpass =
                  (document.getElementById("passcon") as HTMLInputElement)
                    .value + "";
                if (newpass === conpass) {
                  setColorNewPass("#cccccc");
                  setColorConPass("#cccccc");
                  changePassword(pass, newpass, (resp: any) => {
                    if (resp.log) {
                      Messager.message("Erro", resp.log);
                    } else {
                      Messager.message("Sucesso!", resp.message);
                    }
                  });
                } else {
                  setColorNewPass("red");
                  setColorConPass("red");
                }
              }}
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
