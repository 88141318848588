import { UserToken } from "../../../services/UserToken";
import { Raffle } from "../PaginaInicio";
import { Ticket } from "./Ticket";
import { useAuth } from "../../../contexts/AuthContext";
import { Messager } from "../MainPage";
import fonts from "../../../config/fonts";
import { CampaignCategory } from "../../../services/login";

export interface CardProps {
  indx?: string;
  reminder?: boolean;
  raffle: Raffle;
  btLabel?:string;
}

export function Card({ indx, reminder, raffle, btLabel }: CardProps) {
  const icon = raffle.imageFiles[0];
  const cardheadimage = raffle.bannerImageFile;
  const { hasPermission } = useAuth();
  const line = raffle.drawDateTime.substring(
    0,
    raffle.drawDateTime.indexOf("T")
  );
  const gray = !raffle.isActive;

  return (
    <div
      key={"Card_" + indx}
      style={{
        width: "355px",
        maxWidth: "355px",
        backgroundColor: gray ? "#444444" : "#474128",
        padding: "10px",
        borderStyle: "solid",
        borderWidth: "1px",
        borderRadius: "30px",
        marginLeft: "30px",
        borderColor: gray ? "#CCCCCC" : "#C1A162",
      }}
    >
      <img
        src={cardheadimage}
        width="100%"
        height="120px"
        style={{
          borderTopRightRadius: "30px",
          borderTopLeftRadius: "30px",
          paddingBottom: "20px",
        }}
      />
      {gray ? (
        <div style={{ position: "relative", marginBottom: "6px" }}>
          <div
            style={{
              position: "absolute",
              top: "-106px",
              left: "100px",
              color: "white",
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <img src="trofeu.png" /> <br />
            <div
              style={{
                backgroundColor: "#00000099",
                paddingInline: "10px",
                paddingBlock: "6px",
                fontFamily: fonts.bold,
              }}
            >
              SORTEIO ENCERRADO
            </div>
          </div>
        </div>
      ) : (
        <div></div>
      )}
      <div
        style={{
          textAlign: "center",
          color: "white",
          fontWeight: "bold",
          fontSize: "30px",
        }}
      >
        Campanha de <br/>{CampaignCategory.translateInv(raffle.campaign)}
      </div>
      <hr color={gray ? "#333333" : "#b1955b"} />
      <div
        style={{
          textAlign: "center",
          color: "white",
          paddingBottom: "20px",
        }}
      >
        {raffle.description + ""}
      </div>
      <Ticket
        prize={raffle.prizes[0].name}
        icon={icon}
        codigo={line.length === 9 ? line : undefined}
        data={line.length !== 9 ? line : undefined}
        ranking={raffle.prizes[0].order + "º lugar"}
        silver={gray}
      />
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div />
        <button
          style={{
            borderRadius: "20px",
            borderColor: "transparent",
            backgroundColor: gray ? "#333333" : "#C1A162",
            alignSelf: "center",
            width: "80%",
            marginTop: "10px",
          }}
          onClick={() => {
            if (hasPermission("ROLE_CLIENT")) {
              if(btLabel!=="CONTEMPLAR"){
                window.location.href =
                window.location.href.substring(
                  0,
                  window.location.href.indexOf("?")
                ) +
                "?pag=8&raffleId=" +
                raffle.id;
              }else{
                window.location.href =
                window.location.href.substring(
                  0,
                  window.location.href.indexOf("?")
                ) +
                "?pag=10&raffleId=" +
                raffle.id;
              }
            } else {
              Messager.message(
                "Ops...",
                "É necessário estar logado para comprar cupons."
              );
            } /*
            if (raffle.isActive) {
              window.location.href =
                window.location.href.substring(
                  0,
                  window.location.href.indexOf("?")
                ) +
                "?pag=8&raffleId=" +
                raffle.id;
            }*/
          }}
        >
          <p
            style={{
              color: "white",
              fontWeight: "bold",
              textAlign: "center",
              padding: "8px",
              margin: "0px",
              fontSize: "18px",
            }}
          >
            {btLabel?btLabel:raffle.isActive ? "COMPRAR CUPOM" : "CONFERIR SORTEIO"}
          </p>
        </button>
        <div />
      </div>
    </div>
  );
}
