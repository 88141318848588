import { httpConfig, httpMethod } from "./httpConfig";

interface Params {
  [key: string]: any | FormData;
}
type FetchDataFunction = (options: {
  url: string;
  method: string;
  authorization: string;
  params?: Params;
  setLoading?: (isLoading: boolean) => void;
  setErrorMessage?: (title: string, msg: string) => void;
  setResponse?: (resp: any) => void;
}) => Promise<any>;

type FetchDataFunctionNoAuthorization = (options: {
  url: string;
  method: string;
  params?: Params;
  setLoading?: (isLoading: boolean) => void;
  setErrorMessage?: (title: string, msg: string) => void;
}) => Promise<any>;

const fetchData: FetchDataFunction = async (original) => {
  const {
    url,
    method,
    authorization,
    params,
    setLoading,
    setErrorMessage,
    setResponse,
  } = original;

  let body = null;
  if (params) {
    body = params instanceof FormData
      ? params
      : JSON.stringify(params)
  }

  try {
    setLoading && setLoading(true);
    const response = await fetch(url, {
      method: method,
      body,
      headers: {
        Authorization: authorization,
      },
    });
    if (response.status === 401) {
      //document.dispatchEvent(new CustomEvent("logout"));
      return null;
    }

    if (response.status === 400) {
      const data = await response.json();
      setErrorMessage && setErrorMessage("Erro!", data.log);
      return null;
    }

    if (response.status === 500) {
      const data = await response.json();
      setErrorMessage && setErrorMessage("Erro!", data.log);
      return null;
    }
    const data = await response.text();
    if (setResponse) {
      setResponse(data);
    }
    return data;
  } catch (error) {
    console.log(error);
    setErrorMessage && setErrorMessage("Erro!", error + "");
  } finally {
    setLoading && setLoading(false);
  }
};

const fetchDataNoAuthorization: FetchDataFunctionNoAuthorization = async (
  original
) => {
  const { url, method, params, setLoading, setErrorMessage } =
    original;

  try {
    setLoading && setLoading(true);

    let body = null;
    if (params) {
      body = params instanceof FormData
        ? params
        : JSON.stringify(params)
    }

    const response = await fetch(url, {
      method: method,
      body,
    });
    if (response.status === 401) {
      //document.dispatchEvent(new CustomEvent("logout"));
      return null;
    }

    if (response.status === 400) {
      const data = await response.json();
      setErrorMessage &&
        setErrorMessage("Erro!", data.log ? data.log : data.message);
      return null;
    }

    if (response.status === 500) {
      const data = await response.json();
      setErrorMessage &&
        setErrorMessage("Erro!", data.log ? data.log : data.message);
      return null;
    }
    return await response.json();
  } catch (error) {
    console.log(error);
    setErrorMessage && setErrorMessage("Erro!", error + "");
  } finally {
    setLoading && setLoading(false);
  }
};

export const registerNewUser = async (
  params: Params,
  setLoading?: (isLoading: boolean) => void,
  setErrorMessage?: (title: string, msg: string) => void
) => {
  const options = {
    url: httpConfig.backendUrl + "/client/register",
    method: httpMethod.post,
    params,
    setLoading,
    setErrorMessage,
  };
  return fetchDataNoAuthorization(options);
};
