import { animate, motion } from "framer-motion";
import { useAuth } from "../../../contexts/AuthContext";
import { Messager } from "../MainPage";

export interface Props {
  image: any;
  top: string;
  desc: string;
  raffleId: number;
  setSelected: React.Dispatch<React.SetStateAction<number | undefined>>;
  selected: number | undefined;
  index: number;
  isAlive: boolean;
}

export function ShowcaseItem({
  image,
  top,
  desc,
  raffleId,
  setSelected,
  selected,
  index,
  isAlive,
}: Props) {
  const { hasPermission } = useAuth();
  return (
    <motion.div
      style={{
        backgroundColor: "#b0935a",
        borderRadius: "15px",
        borderStyle: "none",
        position: "relative",
        overflow: "hidden",
        width: "100px",
        height: "200px",
      }}
      animate={{
        width: selected === index ? 450 : index === -1 ? 200 : 150,
      }}
      onHoverStart={() => {
        setSelected(index);
      }}
      onClick={()=>{
        setSelected(index);
      }}
    >
      <img
        style={{
          position: "absolute",
          left: "10px",
          top: "20px",
          width: "220px",
        }}
        src={image}
      />
      <div
        style={{
          position: "absolute",
          left: "240px",
          top: "20px",
          whiteSpace: "nowrap",
          textAlign: "right",
          fontSize: "20px",
        }}
      >
        <b>
          <div style={{ fontSize: "22px" }}>Número Ganhador!</div>
          <hr color="black" style={{ borderTopStyle: "none" }} />
          {top}
          <br />{" "}
          {desc.indexOf(" ", 15) != -1
            ? desc.substring(0, desc.indexOf(" ", 15))
            : desc}
        </b>
        <br />
        <button
          style={{
            borderRadius: "20px",
            borderColor: "transparent",
            backgroundColor: "black",
            alignSelf: "center",
            width: "80%",
            paddingRight: "10px",
            fontSize: "10px",
            textAlign: "center",
            fontWeight: "bold",
            color: "white",
            whiteSpace: "nowrap",
            padding: "5px",
          }}
          onClick={() => {
            if (hasPermission("ROLE_CLIENT")) {
              window.location.href =
                window.location.href.substring(
                  0,
                  window.location.href.indexOf("?")
                ) +
                "?pag=8&raffleId=" +
                raffleId;
            } else {
              Messager.message(
                "Ops...",
                "É necessário estar logado para comprar cupons."
              );
            }
          }}
        >
          {isAlive ? "COMPRAR CUPOM" : "CONFERIR SORTEIO"}
        </button>
      </div>
      <img
        style={{
          position: "absolute",
          left: "250px",
          top: "0px",
          pointerEvents: "none",
        }}
        src="watermark.png"
      />
    </motion.div>
  );
}
